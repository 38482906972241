import { get, hasIn, isNull } from "lodash";
import BButton from "@root/common/BButton";
import IconBox from "@root/common/IconBox";
import LoggerService from "@root/services/logger";
import LocaleService from "@root/services/locale";
import GoogleMap from "@root/common/GoogleMap";
import GoogleMapService from "@root/services/googlemap";
import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";
import LocateMe from "./components/LocateMe";
import Places from "./components/Places";

function MapView({ mapData, onSelectLocation }) {
  const modalRef = useRef(null);
  const { t } = LocaleService.useLocale();
  const [draging, setDraging] = useState(false);
  const [placeDetail, setPlaceDetail] = useState(null);
  const [mapLocation, setMapLocation] = useState(null);

  useEffect(() => {
    if (!hasIn(mapData, ["location", "lat"])) return;

    let lat = parseFloat(mapData?.location?.lat);
    let lng = parseFloat(mapData?.location?.lng);
    setMapLocation({ lat, lng });
    setPlaceDetail(mapData.address);
  }, [mapData]);

  const defaultProps = {
    zoom: 9,
    lat: 25.254669974997736,
    long: 55.21101900000002,
  };

  const onDrag = () => {
    setDraging(true);
  };

  const onDragEnd = (latlng) => {
    setDraging(false);
    setMapLocation(latlng);
    reverseCode(latlng);
  };

  const onMapChange = (latlng) => {};

  const onClickLocateMe = (latlng) => {
    reverseCode(latlng);
    setMapLocation(latlng);
  };

  const onSelectPlace = (place) => {
    setPlaceDetail(place?.address);
    setMapLocation(place?.location);
    modalRef.current.hide();
  };

  const reverseCode = (latlng) => {
    GoogleMapService.reverseGeoCodeByLatLng(latlng)
      .then((result) => {
        let results = get(result, ["results"], []);
        let place = GoogleMapService.parseAddress(results[0]);
        setPlaceDetail(place?.address);
      })
      .catch((error) => LoggerService.logError("Reverse Geocode Failed!", error));
  };

  const onClickConfirm = () => {
    onSelectLocation({ location: mapLocation, address: placeDetail, image: null });
  };

  return (
    <>
      <div className={styles.map}>
        <div className={styles.map_search}>
          <Places onSelect={onSelectPlace} />
        </div>
        <IconBox
          name={draging ? "circle-medium" : "map-marker"}
          className={styles.map_marker}
        />
        <GoogleMap
          onDrag={onDrag}
          location={mapLocation}
          initial={defaultProps}
          onDragEnd={onDragEnd}
          onChange={onMapChange}
        />
        <div className={styles.map_locate}>
          <LocateMe onLocate={onClickLocateMe} />
        </div>
        <div className={styles.map_button}>
          <BButton
            onPress={onClickConfirm}
            disabled={isNull(mapLocation)}
            label={t("account_address_map_confirm_location")}
          />
        </div>
      </div>
    </>
  );
}

export default MapView;
