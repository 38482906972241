import LocaleService from "@root/services/locale";
import { isValidCoordinate } from "@root/libs/utils";
import React, { useEffect, useCallback } from "react";
import GoogleMapService from "@root/services/googlemap";
import { get, debounce, hasIn, isFunction } from "lodash";

function GoogleMap({
  location,
  initial = { lat, long, zoom },
  onChange,
  onDrag,
  onDragEnd,
}) {
  useEffect(() => {
    if(!hasIn(location, ["lat"])) return;
    let lat = get(location, ["lat"]);
    let lng = get(location, ["lng"]);
    if (!isValidCoordinate(lat) || !isValidCoordinate(lng)) return;

    GoogleMapService.map.panTo({lat: location.lat, lng: location.lng});
    GoogleMapService.map.setZoom(location.zoom ? location.zoom : 17);
  }, [location]);

  useEffect(() => {
    let subscription = GoogleMapService.$isReady.subscribe((ready) => {
      if (!ready) return;

      let element = document.getElementById("map");
      GoogleMapService.generateMap(element, initial);
      GoogleMapService.listen("drag", drag);
      GoogleMapService.listen("dragend", dragEnd);
      // GoogleMapService.listen("center_changed", centerChange);
    });

    return () => {
      subscription.unsubscribe();
      GoogleMapService.clearListeners();
    };
  }, []);

  const drag = () => {
    isFunction(onDrag) && onDrag();
  };

  const dragEnd = () => {
    if(isFunction(onDragEnd)) {
      let center = GoogleMapService.map.getCenter();
      let zoom = GoogleMapService.map.getZoom();
      let lat = center.lat();
      let lng = center.lng();
      onDragEnd({ lat, lng, zoom });
    }
  };

  const centerChange = useCallback(
    debounce(() => {
      let center = GoogleMapService.map.getCenter();
      let lat = center.lat();
      let lng = center.lng();
      onChange({ lat, lng });
    }, 200),
    []
  );

  return <div id="map" style={{ height: "100%", width: "100%" }} />;
}

export default GoogleMap;
