import { firstValueFrom } from "rxjs";
import { toast } from "react-toastify";
import BButton from "@root/common/BButton";
import FormText from "@root/common/FormText";
import FormCheck from "@root/common/FormCheck";
import FormInput from "@root/common/FormInput";
import FormMobile from "@root/common/FormMobile";
import FormSelect from "@root/common/FormSelect";
import LoggerService from "@root/services/logger";
import LocaleService from "@root/services/locale";
import EventsService from "@root/services/events";
import React, { useEffect, useState } from "react";
import AddressService from "@root/services/address";
import DirectoryService from "@root/services/directory";
import { map as rxmap, switchMap } from "rxjs/operators";
import { SESSION_TIMEOUT } from '@root/constants/events';
import ValidationService from "@root/services/validation";
import { get, map, isUndefined, keys, find, extend } from "lodash";

import styles from "./styles.module.scss";
import FormLocation from "./components/FormLocation";
import ExpressDelivery from "@root/common/ExpressDelivery";

function FormView({ isNew, address, mapData, onLocation, onSuccess }) {
  const {t} = LocaleService.useLocale();
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if(keys(address).length === 0) return;
    setFormData(address);
  }, [address])

  /** fetch cities */
  useEffect(() => {
    firstValueFrom(fetchCities())
      .then((cities) => {
        setCities(cities);
        if(isNew) fillMapCity(cities); // only if new addres
      })
      .catch((error) => LoggerService.logError("Cities data fetch failed!", error));
  }, []);

  /** fetch regions */
  useEffect(() => {
    if (isUndefined(formData.region_id)) return;

    firstValueFrom(fetchRegions(formData.region_id))
      .then((regions) => {
        setRegions(regions);
        if(isNew) fillMapRegion({...formData}, regions) // only if new addres
      })
      .catch((error) => LoggerService.logError("Regions data fetch failed!", error));
  }, [formData.region_id]);

  const fillMapCity = (cities) => {
    let city = get(mapData, ['address', 'city'], '');
    let foundcity = find(cities, {label: city});
    let cityid = get(foundcity, ['value']);
    let extended = extend({}, formData, address, {region_id: cityid});
    setFormData(extended);
  }

  const fillMapRegion = (data, regions) => {
    let area = get(mapData, ['address', 'area'], '');
    let foundarea = find(regions, {label: area});
    let areaid = get(foundarea, ['value']);
    setFormData(extend({}, data, address, {city: areaid}));
  }

  const onPressSetDefault = (value) => {
    setFormData({
      ...formData,
      default_shipping: value,
      default_billing: value,
    });
  };

  const onFormInput = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const onPresSend = () => {
    setFormErrors({});
    verifyForm(() => {
      setLoading(true);
      let tosend = AddressService.maskData(mapData, formData);
      firstValueFrom(addEditaddress(tosend).pipe(
        switchMap((result) => {
          let id = get(result, ['data', 'address_id']);
          return AddressService.addressDetail(id);
        })
      ))
        .then((address) => onSuccess(address))
        .catch((error) => {
          if (error.code === 401) EventsService.trigger({type: SESSION_TIMEOUT});
          else toast.error(get(error, ["message"], error));
          LoggerService.logError('Address Form issue!', error);
        })
        .finally(() => setLoading(false));
    });
  };

  const verifyForm = (cb) => {
    ValidationService.isValid(formData, "web_address_form")
      .then(() => cb())
      .catch((errors) => setFormErrors(errors));
  };

  const fetchRegions = (city) => {
    return DirectoryService.getRegionsList(city).pipe(
      rxmap((result) => {
        let list = get(result, ["data"], []);
        
        return map(list, (item) => ({
          label: item.name,
          value: item.name,
          express_delivery_available: item.express_delivery_available
        }));
      })
    );
  };

  const fetchCities = () => {
    return DirectoryService.getCitiesList().pipe(
      rxmap((result) => {
        let list = get(result, ["data"], []);
        return map(list, (item) => ({
          label: get(item, ["name"], ""),
          value: get(item, ["id"], ""),
        }));
      })
    );
  };

  const addEditaddress = (tosend) => {
    if (isUndefined(formData.id)) return AddressService.addAddress(tosend);
    else return AddressService.updateAddress(formData.id, tosend);
  };

  return (
    <div className={styles.address}>
      <div className="br_row br_column br_mb20">
          <FormLocation
            value={mapData}
            onClick={onLocation}
            attributes={{ placeholder: t('account_address_map_search_location') }}
          />
      </div>
      <div className="br_row br_xsmall_p10">
        <div className="br_xsmall_12 br_columns br_mb20">
          <FormText
            value={formData.firstname}
            error={ValidationService.error(formErrors, "firstname")}
            attributes={{ placeholder: t('br_register_first_name') }}
            onChange={(value) => onFormInput("firstname", value)}
          />
        </div>
        <div className="br_xsmall_12 br_columns br_mb20">
          <FormText
            value={formData.lastname}
            error={ValidationService.error(formErrors, "lastname")}
            attributes={{ placeholder: t('br_register_last_name') }}
            onChange={(value) => onFormInput("lastname", value)}
          />
        </div>
      </div>
      <div className="br_row br_xsmall_p10">
        <div className="br_xsmall_12 br_columns br_mb20">
          <FormSelect
            options={cities}
            value={formData.region_id}
            error={ValidationService.error(formErrors, "region_id")}
            attributes={{ placeholder: t('br_city') }}
            onChange={(value) => onFormInput("region_id", value)}
          />
        </div>
        <div className="br_xsmall_12 br_columns br_mb20">
         <FormSelect
            options={regions}
            value={formData.city}
            error={ValidationService.error(formErrors, "city")}
            attributes={{ placeholder: t('br_region') }}
            onChange={(value) => onFormInput("city", value)}
          />
        </div>
      </div>
      <div className="br_row br_column br_mb20">
        <FormMobile
          value={formData.telephone}
          error={ValidationService.error(formErrors, "telephone")}
          attributes={{ placeholder: t('br_register_enter_mobile') }}
          onChange={(value) => onFormInput("telephone", value)}
        />
      </div>
      <div className="br_row br_small_p10">
        <div className="br_small_12 br_columns br_mb20">
          <FormText
            value={formData.street1}
            error={ValidationService.error(formErrors, "street1")}
            attributes={{ placeholder: t('br_address_apt_villa_number') }}
            onChange={(value) => onFormInput("street1", value)}
          />
        </div>
        <div className="br_small_12 br_columns br_mb20">
          <FormText
            value={formData.street2}
            error={ValidationService.error(formErrors, "street2")}
            attributes={{ placeholder: t('br_address_building_name') }}
            onChange={(value) => onFormInput("street2", value)}
          />
        </div>
      </div>
      <div className="br_row br_column br_mb20">
        <FormInput
          value={formData.street3}
          error={ValidationService.error(formErrors, "street3")}
          attributes={{ rows: 3, placeholder: t('br_address_street_number') }}
          onChange={(value) => onFormInput("street3", value)}
        />
      </div>
      <div className="br_row br_column">
        <FormCheck
          selected={formData.default_shipping}
          label={t('account_address_set_default')}
          onChange={onPressSetDefault}
        />
      </div>
      <div className={styles.address_row}>
        {address?.express_delivery_available && <span>
          <ExpressDelivery size={15} icon={'truck-delivery'} />
        </span>}

        <span className={styles.address_button}>
          <BButton loading={loading} onPress={onPresSend} label={t('br_save')} />
        </span>
      </div>
    </div>
  );
}

export default FormView;
