import clsx from "clsx";
import React from "react";
import LocaleService from "@root/services/locale";
import Image from "next/image";
import { get, hasIn, isNull, isUndefined } from "lodash";

import styles from "./styles.module.scss";

function FormLocation({ error, value, onClick, attributes }) {
  const { t } = LocaleService.useLocale();
  
  const isValid = () => {
    return !isUndefined(value) && !isNull(value) && hasIn(value, ["address"]);
  };

  const addressArea = () => {
    let result = [];
    let premise = get(value, ["address", "premise"]);
    let point_of_interest = get(value, ["address", "point_of_interest"]);
    let route = get(value, ["address", "route"]);
    let area = get(value, ["address", "area"]);

    if (premise) result.push(premise);
    else if (point_of_interest) result.push(point_of_interest);
    if (route) result.push(route);

    result.push(area);
    return result.join(", ");
  };

  const addressCity = () => {
    return get(value, ["address", "city"]);
  };

  return (
    <>
      <div className={styles.formlocation}>
        <div
          onClick={onClick}
          className={clsx(
            styles.formlocation_box,
            isValid() && styles.valid,
            error && styles.error
          )}
        >
          {isValid() ? (
            <>
              <span className={styles.formlocation_title}>{addressArea()}</span>
              <span className={styles.formlocation_text}>{addressCity()}</span>
              <a className={styles.formlocation_edit}>
                <Image
                  alt=""
                  width={50}
                  height={50}
                  src={require("@root/assets/images/map.jpeg")}
                />
                <span className={styles.formlocation_edit_text}>{t('br_edit')}</span>
              </a>
            </>
          ) : (
            <span className={styles.formlocation_placeholder}>
              {attributes?.placeholder}
            </span>
          )}
        </div>
        <span className={styles.formlocation_error}>{error}</span>
      </div>
    </>
  );
}

export default FormLocation;
