import { get } from "lodash";
import React, { useState } from "react";
import LocaleService from "@root/services/locale";
import { toast } from "react-toastify";
import IconBox from "@root/common/IconBox";
import Spinner from "@root/common/Spinner";
import GoogleMapService from "@root/services/googlemap";

import styles from "./styles.module.scss";

function LocateMe({ onLocate }) {
  const {t} = LocaleService.useLocale();
  const [loading, setLoading] = useState(false);

  const onClickLocateMe = () => {
    setLoading(true);
    GoogleMapService.getLocation()
      .then((result) => {
        let lat = get(result, ["coords", "latitude"]);
        let lng = get(result, ["coords", "longitude"]);
        onLocate({ lat, lng });
      })
      .catch((error) => toast.error(get(error, ["message"], error)))
      .finally(() => setLoading(false));
  };

  return (
    <a onClick={onClickLocateMe} className={styles.locateme}>
      {loading ? (
        <Spinner size={16} />
      ) : (
        <>
          <IconBox name="crosshairs-gps" className={styles.locateme_icon} />
          <span className={styles.locateme_text}>{t('account_address_locate_me')}</span>
        </>
      )}
    </a>
  );
}

export default LocateMe;
