import clsx from "clsx";
import React from "react";
import IconBox from "../IconBox";
import { isFunction } from "lodash";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";

function FormCheck({ label, selected, onChange, className }) {
  const { t } = LocaleService.useLocale();
  
  const onClickInput = () => {
    isFunction(onChange) && onChange(!selected);
  };

  return (
    <a onClick={onClickInput} className={clsx(styles.formcheck, className)}>
      <IconBox
        name={selected ? "checkbox-marked" : "checkbox-blank-outline"}
        className={styles.formcheck_icon}
      />
      <span className={styles.formcheck_title}>{label}</span>
    </a>
  );
}

export default FormCheck;
