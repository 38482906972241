import {extend} from 'lodash';
import MapView from '@root/common/MapView';
import {isValidObj} from '@root/libs/utils';
import UserService from '@root/services/user';
import React, {useEffect, useState} from 'react';

import styles from './styles.module.scss';
import FormView from './components/FormView';

const STEP_MAP = 'map';
const STEP_DETAIL = 'detail';
const STEP_REGION = 'region';

function Address({data, onDone}) {
  const isNew = !isValidObj(data);
  const [mapData, setMapData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [steps, setSteps] = useState(isNew ? STEP_MAP : STEP_DETAIL);

  useEffect(() => {
    if (isNew) {
      let extended = extend({}, data?.addressData, {
        firstname: UserService.firstname,
        lastname: UserService.lastname,
        telephone: UserService.mobile,
      });
      setMapData(data?.mapData);
      setAddressData(extended);
    } else {
      setMapData(data?.mapData);
      setAddressData(data?.addressData);
    }
  }, [data]);

  const onClickLocation = () => {
    setSteps(STEP_MAP);
  };

  const onConfirmLocation = data => {
    setMapData(data);
    setSteps(STEP_DETAIL);
  };

  return (
    <div className={styles.address}>
      {steps === STEP_MAP && (
        <MapView mapData={mapData} onSelectLocation={onConfirmLocation} />
      )}
      {steps === STEP_DETAIL && (
        <FormView
          isNew={isNew}
          mapData={mapData}
          onSuccess={onDone}
          address={addressData}
          onLocation={onClickLocation}
        />
      )}
    </div>
  );
}

export default Address;
