import {isNull, map} from 'lodash';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';
import LoggerService from '@root/services/logger';
import GoogleMapService from '@root/services/googlemap';
import React, {useState, useRef, useEffect} from 'react';

import styles from './styles.module.scss';

function Places({onSelect}) {
  const inputRef = useRef(null);
  const {t} = LocaleService.useLocale();
  const [textInput, setTextInput] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  useEffect(() => {
    if (isNull(inputRef.current)) return;
    inputRef.current.focus();
  }, []);

  const onChangeSearch = event => {
    let value = event.target.value;
    setTextInput(value);
    GoogleMapService.autocomplete(value)
      .then(result => setSearchSuggestions(result))
      .catch(() => setSearchSuggestions(null));
  };

  const onClickSelect = place_id => {
    GoogleMapService.placeDetail(place_id)
      .then(result => onSelect(GoogleMapService.parseAddress(result)))
      .catch(error => LoggerService.logError('Location details error!', error))
      .finally(() => clearState());
  };

  const clearState = () => {
    setTextInput('');
    setSearchSuggestions([]);
  };

  return (
    <div className={styles.places}>
      <div className={styles.places_form}>
        <IconBox className={styles.places_icon} name="magnify" />
        <input
          type="text"
          ref={inputRef}
          value={textInput}
          onChange={onChangeSearch}
          className={styles.places_input}
          placeholder="Search your location"
        />
      </div>
      {!isNull(searchSuggestions) && (
        <ul className={styles.places_list}>
          {map(searchSuggestions, suggestion => {
            let description = suggestion.description;
            let matched = description.slice(
              suggestion.matched_substrings[0].offset,
              suggestion.matched_substrings[0].length,
            );
            let rest = description.slice(
              suggestion.matched_substrings[0].length,
            );
            return (
              <li key={suggestion.place_id} className={styles.places_item}>
                <a
                  onClick={() => onClickSelect(suggestion.place_id)}
                  className={styles.places_link}>
                  <strong>{matched}</strong>
                  {rest}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default Places;
